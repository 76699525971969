import React, {useState} from 'react';
import {Auth} from "@aws-amplify/auth";
import {Button, Col, Container, Form, Row} from "react-bootstrap";

export default function Login({setIsAuthenticated}: {setIsAuthenticated:  React.Dispatch<React.SetStateAction<boolean>>}) {
    const [email, setEmail] = useState("");
    const [formText, setFormText] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState("");

    function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setEmail(event.currentTarget.value);
    }

    function handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setPassword(event.currentTarget.value);
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        Auth.signIn(email, password)
            .then(() => {
                setIsAuthenticated(true);
            })
            .catch((err) => {
                if (err && err.code === 'UserNotFoundException') {
                    console.log('User not found');
                    setFormText('Username or password is incorrect.');
                } else {
                    setFormText('Something went wrong when logging in. Make sure your username and password are correct.');
                }
            });
    }

    return (
        <Container fluid={"lg"}>
            <Col>
                <Row className={"justify-content-center"}>
                    <img className={"justify-content-center"} src={"/logo.JPG"} width={"500"} alt={"my logo"}/>
                </Row>
                <Form onSubmit={handleSubmit}>
                    <Row className={"justify-content-center"}>
                            <Form.Group controlId={"formBasicUsername"}>
                                <Form.Label>Username</Form.Label>
                                <Form.Control size={"lg"} placeholder={"Username"} value={email}
                                            onChange={handleEmailChange}/>
                            </Form.Group>

                    </Row>
                    <Row className={"justify-content-center"}>
                        <Form.Group controlId={"formBasicPassword"}>
                            <Form.Label>Password</Form.Label>
                            <Form.Control size={"lg"} type={"password"} placeholder={"Password"} value={password}
                                        onChange={handlePasswordChange}/>
                        </Form.Group>
                    </Row>
                    <Row className={"justify-content-center"}>
                        <Button variant={"primary"} type={"submit"}>
                            Log In
                        </Button>
                    </Row>
                    <Row className={"justify-content-center"}>
                        {formText && <Form.Text style={{color: 'red'}} id="loginFormText">{formText}</Form.Text>}
                    </Row>
                </Form>
            </Col>

        </Container>
    );
}