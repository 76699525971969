import React, {useEffect, useState} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthorizedOnlyRedirector from "./components/Authentication/Redirect";
import { Zauvu } from "@stratuspartnersllc/zauvu-api";
import {API} from '@aws-amplify/api';
import {Auth} from '@aws-amplify/auth';
import { Storage } from '@aws-amplify/storage'
import config from './aws-exports';
API.configure(config);
Auth.configure(config);
Storage.configure(config);
Zauvu.configure({
    S3_SERVICE_URL: process.env.REACT_APP_S3_URL || '',
    REST_API_URL: process.env.REACT_APP_REST_API_URL || '',
})

function App() {
    const defaultState = false;
    const [isAuthenticated, setIsAuthenticated] =useState(defaultState);

    useEffect(() => {
        // @ts-ignore
        Auth.currentAuthenticatedUser().then((res) => {
            if (res) {
                setIsAuthenticated(true);
            }
        }).catch(() => {
            setIsAuthenticated(false);
        });
    }, [isAuthenticated]);

    return (
        <AuthorizedOnlyRedirector isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
    );
}

export default App
