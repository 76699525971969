import React, {useEffect, useState} from "react";
import {Button, Table} from "react-bootstrap";
import {BetaWaitList} from "@stratuspartnersllc/zauvu-api";
import type {WaitListEmail as IBetaWaitList} from "@stratuspartnersllc/zauvu-api/build/src/API";

export default function WaitListTable() {
    const [waitListCount, setWaitListCount] = useState<number | undefined>();
    const [waitListItems, setWaitListItems] = useState<IBetaWaitList[] | undefined>();

    useEffect(() => {
        const loadWaitList = async () => {
            if (waitListItems) return;
            const waitList = await BetaWaitList.listWaitListEmail();
            setWaitListItems(waitList.items);
            setWaitListCount(waitList.items.length);
        }
        loadWaitList();
    }, [waitListItems, setWaitListItems, waitListCount, setWaitListCount]);

    const deleteWaitListItem = async (id: string, index: number) => {
        await BetaWaitList.deleteWaitListEmail(id);
        removeWaitList(index);
    };

    const removeWaitList = (index: number) => {
        if (!waitListItems) return;
        setWaitListItems(waitListItems.filter((waitList, i) => {
            return index !== i;
        }));
    }

    function displayBetaKeyList() {
        if (!waitListItems) return;
        return waitListItems.map((feedback, index) => {
           return (
               <tr key={feedback.id}>
                   <td>{feedback.id}</td>
                   <td>{feedback.createdAt}</td>
                   <td>{<Button onClick={() => deleteWaitListItem(feedback.id as string, index)}>Delete</Button>}</td>
               </tr>
           )
        });
    }

    return (
        <>
        <h1>Wait List - {waitListCount}</h1>
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Email</th>
                <th>Created At</th>
                <th>Delete</th>
            </tr>
            </thead>
            <tbody>
                {waitListItems ? displayBetaKeyList() : <tr/>}
            </tbody>
        </Table>
        </>
    );
}