import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import WaitListTable from "./WaitListTable";

export default function WaitListDashboard() {
    return (
        <Container >
            <Row>
                <WaitListTable />
            </Row>
        </Container>
    );
}