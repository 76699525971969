import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Login from "./Authentication/Login/Login";
import HomeDashboard from "./Dashboards/HomeDashboard/HomeDashboard";
import ResourceDashboard from "./Dashboards/ResourceDashboard/ResourceDashboard";
import ZauvuInternalNavbar from "./Dashboards/Navbar/ZauvuInternalNavbar";
import BetaKeyDashboard from "./Dashboards/BetaKeyDashboard/BetaKeyDashboard";
import FeedbackDashboard from "./Dashboards/FeedbackDashboard/FeedbackDashboard";
import WaitListDashboard from './Dashboards/WaitListDashboard/WaitListDashboard';

export default function InternalRouter({setIsAuthenticated}: {setIsAuthenticated:  React.Dispatch<React.SetStateAction<boolean>>}) {
    function authorizedContent() {
        return (
            <div>
                <ZauvuInternalNavbar setIsAuthenticated={setIsAuthenticated}/>
                <Router>
                    <Switch>
                        <Route exact path={"/login"}>
                            <Login setIsAuthenticated={setIsAuthenticated}/>
                        </Route>
                        <Route exact path={"/"}>
                            <HomeDashboard />
                        </Route>
                        <Route exact path={"/resources"}>
                            <ResourceDashboard />
                        </Route>
                        <Route exact path={"/betakeys"}>
                            <BetaKeyDashboard />
                        </Route>
                        <Route exact path={"/feedback"}>
                            <FeedbackDashboard />
                        </Route>
                        <Route exact path={"/waitlist"}>
                            <WaitListDashboard />
                        </Route>
                    </Switch>
                </Router>
            </div>
        );
    }

    return (
        <div>
            {authorizedContent()}
        </div>
    );
}