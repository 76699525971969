import React from 'react';
import {Card, Col, Container, Jumbotron, Row} from "react-bootstrap";

function HomeDashboard() {
    return (
       <Container>
            <Jumbotron>
                <h1>Zauvu Internal</h1>
                <hr />
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Resources</Card.Title>
                                <Card.Subtitle className={"mb-2 text-muted"}>Create, View, Update, Delete</Card.Subtitle>
                                <Card.Text>Management of public Zauvu resources</Card.Text>
                                <Card.Link href={"/resources"}>Resources</Card.Link>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Beta Keys</Card.Title>
                                <Card.Subtitle className={"mb-2 text-muted"}>Create, View, Update, Delete</Card.Subtitle>
                                <Card.Text>Manage Zauvu Beta Keys</Card.Text>
                                <Card.Link href={"/betakeys"}>Beta Keys</Card.Link>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Feedback</Card.Title>
                                <Card.Subtitle className={"mb-2 text-muted"}>View</Card.Subtitle>
                                <Card.Text>View Feedback</Card.Text>
                                <Card.Link href={"/feedback"}>Feedback</Card.Link>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Jumbotron>
       </Container>
    );
}

export default HomeDashboard;