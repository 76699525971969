import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import ResourceUploadModalButton from "./ResourceUploadModalButton";
import ResourceTable from "./ResourceTable";

function ResourceDashboard() {
    return (
        <Container >
            <Row>
                <Col sm={11}>
                    <h2>Resources</h2>
                </Col>
                <Col sm={1}>
                    <ResourceUploadModalButton />
                </Col>
            </Row>
            <Row>
                <ResourceTable />
            </Row>
        </Container>
    );
}

export default ResourceDashboard;