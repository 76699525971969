import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import FeedbackTable from "./FeedbackTable";

export default function FeedbackDashboard() {
    return (
        <Container >
            <Row>
                <Col sm={11}>
                    <h2>Feedback</h2>
                </Col>
            </Row>
            <Row>
                <FeedbackTable />
            </Row>
        </Container>
    );
}