import React, {useEffect, useState} from "react";
import {Modal, Image, Container} from "react-bootstrap";

export default function ImageModalClick({image, width, show, toggleShow}: {image: string | undefined, width: string, show: boolean, toggleShow: Function}) {
    const [showImageModal, setShowImageModal] = useState(show);

    useEffect(() => {
        setShowImageModal(show);
    }, [show]);

    function modal() {
        return(
            <Modal show={showImageModal} onHide={() => {
                setShowImageModal(false);
                toggleShow();
            }}>
                <Modal.Header closeButton>
                    Resource
                </Modal.Header>
                <Modal.Body>
                    <Image src={image} width={width}/>
                </Modal.Body>
            </Modal>
        );
    }

    function imagePreview() {
        return (
            <div>
                {/*<Image src={imagePath ? imagePath : ""} width={0} onClick={() => setShowImageModal(true)}/>*/}
            </div>
        )
    }
    return (
        <Container>
            {showImageModal && modal()}
            {imagePreview()}
        </Container>
    );
}