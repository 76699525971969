import {Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Feedback} from "@stratuspartnersllc/zauvu-api";
import type {Feedback as FeedbackType} from "@stratuspartnersllc/zauvu-api/build/src/API";
import {FeedbackCategory} from "@stratuspartnersllc/zauvu-api/build/src/Feedback";

export default function FeedbackTable() {
    const [feedbackList, setFeedbackList] = useState<FeedbackType[] | null>(null);

    useEffect(() => {
        fetchFeedbackList().then(async (feedbackList) => {
            if (feedbackList === undefined) return;
            setFeedbackList(feedbackList);
        });
    });

    async function fetchFeedbackList() {
        if (feedbackList !== null) return;
        const feedback: FeedbackType[] = [];
        const feedbackListItems = await Feedback.listFeedback(FeedbackCategory.FEEDBACK);
        feedbackListItems.items.forEach(item => feedback.push(item));
        const issueList = await Feedback.listFeedback(FeedbackCategory.ISSUE);
        issueList.items.forEach(item => feedback.push(item));
        const inquiryList = await Feedback.listFeedback(FeedbackCategory.INQUIRY);
        inquiryList.items.forEach(item => feedback.push(item));

        return feedback;
    }

    function displayBetaKeyList() {
        if (feedbackList === null) return;
        return feedbackList.map((feedback) => {
           return (
               <tr key={feedback.id}>
                   <td>{feedback.email}</td>
                   <td>{feedback.owner ? feedback.user?.username : 'N/A'}</td>
                   <td>{feedback.title}</td>
                   <td>{feedback.details}</td>
                   <td>{feedback.category}</td>
                   <td>{feedback.createdAt}</td>
               </tr>
           )
        });
    }

    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Email</th>
                <th>Username</th>
                <th>Title</th>
                <th>Details</th>
                <th>Category</th>
                <th>Created At</th>
            </tr>
            </thead>
            <tbody>
                {feedbackList ? displayBetaKeyList() : <tr/>}
            </tbody>
        </Table>
    );
}