import React, {useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {Beta} from "@stratuspartnersllc/zauvu-api";
import { BetaKey } from "@stratuspartnersllc/zauvu-api/build/src/API";

export interface CreateBetaKeyModalProps {
    onCreate: (value: BetaKey) => void;
}

export default function CreateBetaKeyModal({onCreate}: CreateBetaKeyModalProps) {
    const [email, setEmail] = useState("");
    const [show, setShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    function handleEmail(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        setEmail(e.currentTarget.value.toLowerCase());
    }

    async function submit(e: React.ChangeEvent<EventTarget>) {
        e.preventDefault();

        Beta.BetaClient.createBetaKey(email.toLowerCase()).then((betaKey) => {
            setShow(false);
            setEmail("");
            onCreate(betaKey);
        }).catch((error) => {
            if (error.message === '"Request failed with status code 409"') {
                setErrorMessage('Email has already been added');
            } else {
                setErrorMessage('Looks like something went wrong, please try again later.');
            }
        });
    }

    function modal() {
        return (
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton >
                    <Modal.Title>Add a Beta User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={submit}>
                        <Form.Label>Associate an email with a beta key</Form.Label>
                        <Form.Group controlId={"formBasicEmail"}>
                            <Form.Label>Resource Name</Form.Label>
                            <Form.Control placeholder={"Email"} value={email} onChange={handleEmail} />
                        </Form.Group>
                        <Button variant={"primary"} type={"submit"} >
                            Submit
                        </Button>
                        {errorMessage && <Form.Text style={{color: 'red'}} id="errorMessageText">{errorMessage}</Form.Text>}
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }

    function button() {
        return (
            <Button onClick={() => setShow(true)}>Add</Button>
        );
    }

    return (
        <div>
            {show &&
                modal()
            }
            {
                !show && button()
            }
        </div>
    );
}