import React, {useEffect, useState} from "react";
import { Button, Table, Col, Container, Row } from "react-bootstrap";
import CreateBetaKeyModal from './CreateBetaKeyModal';
import {Beta} from "@stratuspartnersllc/zauvu-api";
import {BetaKey} from "@stratuspartnersllc/zauvu-api/build/src/API";

export default function BetaKeyTable() {
    const [betaKeyList, setBetaKeyList] = useState<BetaKey[] | null>(null);

    useEffect(() => {
        const loadBetaKeys = async () => {
            if (betaKeyList !== null) return;
            const betaKeys = await Beta.listBetaKeys();
            setBetaKeyList(betaKeys.items);
        }

        loadBetaKeys();
    }, [betaKeyList, setBetaKeyList]);

    const removeBeatKey = (index: number) => {
        if (betaKeyList === null) return;
        setBetaKeyList(betaKeyList.filter((betaKey, i) => { 
            return index !== i
        }));
    }

    const onCreate = (value: BetaKey): void => {
        setBetaKeyList([...(betaKeyList ?? []), value]);
    }

    function displayBetaKeyList() {
        if (betaKeyList === null) return;
        return betaKeyList.map((betaKey, index) => {
           return (
               <tr key={betaKey.id}>
                   <td>{betaKey.email}</td>
                   <td>{betaKey?.sender?.name}</td>
                   <td>{betaKey.user ? 'Y' : 'N'}</td>
                   <td>{<Button onClick={() => Beta.BetaClient.deleteBetaKey(betaKey.id as string).then(() => removeBeatKey(index))}>Remove</Button>}</td>
               </tr>
           )
        });
    }

    return (
        <Container >
            <Row>
                <Col sm={11}>
                    <h2>Beta Keys</h2>
                </Col>
                <Col sm={1}>
                    <CreateBetaKeyModal onCreate={onCreate} />
                </Col>
            </Row>
            <Row>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Email</th>
                        <th>Added By</th>
                        <th>Registered</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                        {betaKeyList ? displayBetaKeyList() : <tr/>}
                    </tbody>
                </Table>
            </Row>
        </Container>
    );
}