import React from 'react';
import {Button, Nav, Navbar} from "react-bootstrap";
import {Auth} from "@aws-amplify/auth";

export default function ZauvuInternalNavbar({setIsAuthenticated}: {setIsAuthenticated:  React.Dispatch<React.SetStateAction<boolean>>}) {
    async function handleSignOut() {
        await Auth.signOut().then(() => {
          setIsAuthenticated(false);
        });
    }

    return(
      <Navbar bg={"dark"} variant={"dark"}>
          <Navbar.Brand href={"/"}>Zauvu</Navbar.Brand>
          <Navbar.Collapse>
              <Nav className={"mr-auto"}>
                  <Nav.Link href={"/resources"}>Resources</Nav.Link>
                  <Nav.Link href={"/betakeys"}>Beta Keys</Nav.Link>
                  <Nav.Link href={"/feedback"}>Feedback</Nav.Link>
                  <Nav.Link href={"/waitlist"}>Wait List</Nav.Link>
              </Nav>
          </Navbar.Collapse>
          <Button onClick={handleSignOut}>Sign Out</Button>

      </Navbar>
    );
}