import React, {useEffect, useState} from "react";
import {Button, Table} from "react-bootstrap";
import ImageModalClick from "./ImageModalClick";
import Resource from "../../../types/Resource";
import {Resources} from "@stratuspartnersllc/zauvu-api";

export default function ResourceTable() {
    const [resources, setResources] = useState<Resource[] | null>(null);
    const [show, setShow] = useState(false);
    const [shownImage, setShownImage] = useState('');

    useEffect(() => {
        const fetchResources = () => {
            if (resources !== null) {
                return;
            }
            Resources.listAllResourceNames().then((data: any) => {
                if (!data) return;
                const resourceRows: Resource[] = [];
                data.map((resource: any) => {
                    const dataResource: Resource = {
                        id: resource.id,
                        owner: resource.owner,
                        description: resource.description,
                        resourceName: resource.resourceName,
                        resourcePath: resource.resourcePath
                    }

                    resourceRows.push(dataResource);
                    return dataResource;
                });
                setResources(resourceRows);
            });
        }

        fetchResources();
    }, [resources]);



    function deleteResources(id: string, key: string) {
        Resources.deleteResourceById(id, key).then(() => {
            window.location.reload();
        });
    }

    function view(imagePath: string) {
        setShow(!show);
        if (!show) {
            setShownImage(imagePath);
        }
    }


    function convert() {
        if (resources === null) {
            return;
        }
        return resources.map((resource: Resource) => {
            return (
                <tr key={resource.id}>
                    <td>{resource.resourceName}</td>
                    <td>{resource.resourcePath}</td>
                    <td>{resource.owner}</td>
                    <td>{resource.description}</td>
                    <td>{<Button onClick={() => view(resource.resourcePath)}>View</Button>}<Button onClick={() => deleteResources(resource.id, resource.resourcePath.replace('resource/', ''))}>Delete</Button></td>
                </tr>
            );
        });
    }

    return (<>
          {show && <ImageModalClick toggleShow={view} show={show} image={process.env.REACT_APP_CDN_URL+shownImage} width={"400"} />}
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Resource Name</th>
                <th>File Path</th>
                <th>Uploaded By</th>
                <th>Description</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
                {resources ? convert() : <tr/>}
            </tbody>
        </Table>
      </>

    );
}