import React, {useState} from "react";
import {Button, Form, Image, Modal} from "react-bootstrap";
import {Auth} from "@aws-amplify/auth";
import {Resources} from "@stratuspartnersllc/zauvu-api";

export default function ResourceUploadModalButton() {
    const [file, setFile] = useState<File>();
    const [image, setImage] = useState("");
    const [resourceName, setResourceName] = useState("");
    const [description, setDescription] = useState("");
    const [show, setShow] = useState(false);

    function handleChange(e: React.ChangeEvent<HTMLInputElement>){
        e.preventDefault();
        if (e.target.files !== null) {
            setFile(e.target.files[0]);
            setImage(URL.createObjectURL(e.target.files[0]));
        }
    }

    function handleResourceName(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        setResourceName(e.currentTarget.value);
    }

    function handleDescription(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        setDescription(e.currentTarget.value);
    }

    async function submit(e: React.ChangeEvent<EventTarget>) {
        e.preventDefault();
        const username = (await Auth.currentUserInfo()).username;

        // @ts-ignore
        Resources.uploadResource(resourceName, file, username, description).then(() => {}).then(() => {
            // window.location.reload();
        });
        setShow(false);
    }

    function modal() {
        return (
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton >
                    <Modal.Title>Resource Upload</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={submit}>
                        <Form.Group controlId={"formBasicFile"}>
                            <Form.File.Label>Image</Form.File.Label>
                            <Form.File.Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}/>
                            {file &&
                                (<p>Preview:</p>)
                            }
                            {file &&
                                <Image src={image} width={"300"}/>
                            }

                        </Form.Group>
                        <Form.Label>Uploaded files are publicly accessible from the internet. Do not upload confidential files.</Form.Label>
                        <Form.Group controlId={"formBasicUsername"}>
                            <Form.Label>Resource Name</Form.Label>
                            <Form.Control placeholder={"Username"} value={resourceName} onChange={handleResourceName} />
                        </Form.Group>
                        <Form.Group controlId={"formBasicPassword"}>
                            <Form.Label>Description</Form.Label>
                            <Form.Control placeholder={"Description"} value={description} onChange={handleDescription} />
                        </Form.Group>
                        <Button variant={"primary"} type={"submit"} >
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }

    function button() {
        return (
            <Button onClick={() => setShow(true)}>Upload</Button>
        );
    }

    return (
        <div>
            {show &&
            modal()
            }
            {
                !show && button()
            }
        </div>
    );
}